<template>
    <div>
        <b-card no-body class="mb-0">
            <div class="m-2">
                <b-row>
                    <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <label>Show</label>
                        <v-select v-model="perPage" :options="perPageOptions" :clearable="false"
                                  class="per-page-selector d-inline-block mx-50 ml-1"/>
                    </b-col>
                    <b-col cols="12" md="6">
                        <b-row class="d-flex align-items-center justify-content-end">
                            <b-col class="mb-md-0 mb-2" cols="12 " md="3">
                                <v-select v-if="teamList" v-model="teamFilter" :options="teamList" placeholder="Select Team"
                                          :clearable="true" :reduce="(option) => option.value" label="label" class="w-100" />
                            </b-col>
                            <b-col cols="12" md="3" class="mb-md-0 mb-2">
                                <v-select :options="statusOptions"
                                          :reduce="(option) => option.value" v-model="statusFilter"
                                          placeholder="Select Status" class="w-100"/>
                            </b-col>
                            <b-col cols="12" md="3" class="mb-md-0 mb-2">
                                <b-form-input v-model="searchQuery" class="d-inline-block mr-1"
                                              placeholder="Search..."/>
                            </b-col>
                            <b-col class="mb-md-0 mb-2" cols="12" md="3"
                                   v-if="$can('read', 'appCreate')">
                                <b-button class="w-100" variant="primary"
                                          @click="$router.push('/app/create')">
                                    <span>New App</span>
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </div>
            <b-table ref="refAppListTable" class="position-relative" :items="fetchApps" responsive
                     :table-class="'build-list-table table dataTable no-footer dtr-column'" striped
                     :fields="tableColumns"
                     primary-key="id" :sort-by.sync="sortBy" show-empty empty-text="No matching records found"
                     :sort-desc.sync="isSortDirDesc">
                <template #table-busy>
                    <div class="text-center my-2">
                        <b-spinner class="d-block mx-auto mb-50"></b-spinner>
                        <strong>Loading...</strong>
                    </div>
                </template>
                <template #cell(id)="data">
                    <span class="align-text-top text-capitalize">{{ data.item.id }}</span>
                </template>
                <template #cell(icon)="data">
                    <b-media vertical-align="center">
                        <template #aside>
                            <b-avatar rounded="sm" size="32" :src="backend_url+data.item.icon" :text="avatarText(data.item.name)"
                                :variant="`light-secondary`" />
                        </template>
                    </b-media>
                </template>
                <template #cell(team)="data">
                    <span class="align-text-top text-capitalize" v-if="data.item.team">{{ data.item.team.name }}</span>
                    <span class="align-text-top text-capitalize" v-else>-</span>
                </template>
                <template #cell(created_by)="data">
                    <span class="align-text-top text-capitalize">{{ data.item.user ? data.item.user : '-' }}</span>
                </template>
                <template #cell(automation)="data">
                    <span class="align-text-top text-capitalize">{{ !data.item.automation ? 'Manual' : 'Automation' }}</span>
                </template>
                <template #cell(processing_status)="data">
                    <app-detail-status :appData="data.item"></app-detail-status>
                </template>
                <template #cell(created_at)="data">
                    <span class="align-text-top text-capitalize">{{ dateFormat(data.item.created_at) }}</span>
                </template>
                <template #cell(actions)="data">
                    <b-link class="mr-1" v-if="$can('read', 'appList')"
                            :to="{ name: 'app-detail', params: { app: data.item.id } }"
                            v-b-tooltip.hover.top="'Detail App'">
                        <feather-icon icon="EyeIcon" size="18"/>
                    </b-link>
                    <b-link class="mr-1"
                            v-if="$can('read', 'appEdit') && (data.item.processing_status == 'error' || data.item.processing_status == 'completed') && data.item.automation"
                            :to="{ name: 'app-edit', params: { app: data.item.id } }"
                            v-b-tooltip.hover.top="'Edit App'">
                        <feather-icon icon="EditIcon" size="18"/>
                    </b-link>
                    <b-link class="mr-1"
                            v-else-if="$can('read', 'appEdit') && (data.item.processing_status == 'error' || data.item.processing_status == 'completed')"
                            :to="{ name: 'app-edit-manual', params: { app: data.item.id } }"
                            v-b-tooltip.hover.top="'Edit App'">
                        <feather-icon icon="EditIcon" size="18"/>
                    </b-link>
                    <b-link class="mr-1"
                            v-if="$can('read', 'appDelete') && data.item.deletable && data.item.processing_status == 'error'"
                            v-b-tooltip.hover.top="'Delete App'" v-on:click="confirmDelete(data.item.id)">
                        <feather-icon icon="TrashIcon" size="18"/>
                    </b-link>
                </template>
            </b-table>
            <div class="mx-2 mb-2">
                <b-row>
                    <b-col cols="12" sm="6"
                           class="d-flex align-items-center justify-content-center justify-content-sm-start">
						<span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }}
							entries</span>
                    </b-col>
                    <b-col cols="12" sm="6"
                           class="d-flex align-items-center justify-content-center justify-content-sm-end">
                        <b-pagination v-model="currentPage" :total-rows="totalApps" :per-page="perPage" first-number
                                      last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item"
                                      next-class="next-item">
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18"/>
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18"/>
                            </template>
                        </b-pagination>
                    </b-col>
                </b-row>
            </div>
        </b-card>
    </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import useAppApi from '@/composables/useAppApi'
import moment from 'moment'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AppDetailStatus from '@/components/AppDetailStatus.vue'
import {avatarText} from '@core/utils/filter'
import {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    VBTooltip,
    BSpinner
} from 'bootstrap-vue'
import router from "@/router";

export default {
    components: {
        BSpinner,
        BCard,
        BRow,
        BCol,
        BFormInput,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,
        vSelect,
        AppDetailStatus
    },
    directives: {
        'b-tooltip': VBTooltip,
        Ripple,
    },
    data(){
        return {
            backend_url:process.env.VUE_APP_BACKEND_URL
        }
    },
    setup() {
        const {
            fetchApps,
            tableColumns,
            perPage,
            currentPage,
            totalApps,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refAppListTable,
            refetchData,
            statusFilter,
            teamFilter,
            statusOptions,
            teamList,
            fetchTeamList,
        } = useAppApi()

        return {
            fetchApps,
            tableColumns,
            perPage,
            currentPage,
            totalApps,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refAppListTable,
            refetchData,
            statusOptions,
            statusFilter,
            teamFilter,
            teamList,
            fetchTeamList,
            avatarText
        }
    },
    methods: {
        dateFormat(date) {
            return moment(date).format('YYYY-MM-DD HH:mm')
        },
        confirmDelete(id) {
            this.$swal.fire({
                title: 'Are you sure?',
                text: "Are you sure you want to delete the app?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete!',
                customClass: {
                    confirmButton: 'btn btn-primary mr-1',
                    cancelButton: 'btn btn-outline-primary ms-1'
                },
                buttonsStyling: false
            }).then(result => {
                if (result.value) {
                    this.$store.dispatch('appStore/deleteApp', {
                        id: id
                    }).then(() => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Successfully deleted!',
                                icon: 'CheckIcon',
                                variant: 'success',
                            },
                        })
                    }).then(() => {
                        this.refetchData()
                    })
                }
            })
        }
    },
    mounted() {
        this.fetchTeamList()
    },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
    width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
