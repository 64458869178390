<template>
    <h6 class="text-capitalize">
        <b-badge v-if="appData.processing_status=== 'error'" variant="light-danger">
            <feather-icon icon="XIcon" class="mr-25" /><span>{{ appData.processing_status}}</span>
        </b-badge>
        <b-badge v-else-if="appData.processing_status === 'processing'" variant="light-info">
            <feather-icon icon="ClockIcon" class="mr-25" />
            <span>In Progress</span>
        </b-badge>
        <b-badge v-else-if="appData.processing_status === 'new'" variant="light-success">
            <feather-icon icon="EyeIcon" class="mr-25" />
            <span>{{ appData.processing_status}}</span>
        </b-badge>
        <b-badge v-else-if="appData.processing_status === 'completed'" variant="light-primary">
            <feather-icon icon="CheckCircleIcon" class="mr-25" />
            <span>{{ appData.processing_status}}</span>
        </b-badge>
        <b-badge v-else-if="appData.processing_status === 'updated'" variant="light-warning">
            <feather-icon icon="ClockIcon" class="mr-25" />
            <span>{{ appData.processing_status}}</span>
        </b-badge>
    </h6>
</template>
<script>
import {
	BBadge,
} from 'bootstrap-vue'
export default{
     components: {
       BBadge
    },
    props:{
        appData:{}
    }

}
</script>
